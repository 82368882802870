<template>
  <form-group
    :label="$t(label)"
    v-bind="{
      descriptionId,
      name,
      testid,
      validation,
    }"
    v-slot="{ errors }"
    @errors="onErrors"
  >
    <div
      class="phone-input-group"
      :class="{
        'phone-input-group--has-error': errors.length,
        'phone-input-group--has-tooltip': hasTooltip,
        'phone-input-group--is-required': isRequired,
      }"
      :data-testid="dataTestid"
    >
      <div class="phone-input-group-container">
        <label
          class="phone-input-group-label"
          :class="{ 'phone-input-group-label--is-active': isFocused || value }"
          :for="name"
          :data-testid="`${dataTestid}-label`"
        >
          <span v-html="labelText"/>
          <screen-readers-only>
            {{ ariaLabel }}
          </screen-readers-only>
        </label>
        <tooltip
          v-if="hasTooltip"
          :message="tooltip"
        />
        <vue-tel-input
          ref="phone"
          :aria-describedby="descriptionId"
          :mode="'international'"
          :placeholder="''"
          :preferredCountries="['gb', 'pl']"
          v-model="phone"
          @onInput="onInput"
          @onBlur="toggleIsFocused"
        >
          <template
            #dropdown="{
              sortedCountries,
              activeCountry,
              choose
            }"
          >
            <base-select
              class="prefix-dropdown"
              :name="`${name}_prefix`"
              :options="sortedCountries"
              :value="activeCountry"
              @input="choose"
            >
              <!-- eslint-disable-next-line vue/no-template-shadow -->
              <template #singleLabel="{ value }">
                <div
                  :aria-label="value.name"
                  :class="['vti__flag', value.iso2.toLowerCase()]"
                />
              </template>
              <template #option="{ option }">
                <div class="prefix-dropdown__option">
                  <div
                    aria-hidden="true"
                    :class="[
                      'vti__flag',
                      option.value.iso2.toLowerCase(),
                      'prefix-dropdown__flag'
                    ]"
                  />
                  <span class="prefix-dropdown__country-name">{{ option.value.name }}</span>
                  <span class="prefix-dropdown__prefix">+{{ option.value.dialCode }}</span>
                </div>
              </template>
            </base-select>
          </template>
        </vue-tel-input>
      </div>
    </div>
  </form-group>
</template>

<script>
  import { VueTelInput } from 'vue-tel-input'

  import { BaseSelect } from '@/components/base'
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'
  import Tooltip from '@/components/tooltip/Tooltip'

  import isRequired from '@/mixins/isRequired'

  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseSelect,
      FormGroup,
      ScreenReadersOnly,
      Tooltip,
      VueTelInput,
    },

    mixins: [
      isRequired,
      labelText,
      testid(),
    ],

    props: {
      ariaLabel: {
        type: String,
        default: '',
      },
      isOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      tooltip: {
        type: String,
        required: false,
        default: '',
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: String,
        required: false,
        default: '',
      },
    },

    inject: {
      $validator: '$validator',
    },

    mounted() {
      this.isMounted = true
      this.toggleListeners('add')
      this.setAttributes()
      this.attachValidation()
      this.addAria()
      this.addTestid()
    },

    beforeDestroy() {
      this.toggleListeners('remove')
    },

    watch: {
      isDropdownOpen() {
        this.$emit('update:isOpen', this.isDropdownOpen)
      },
    },

    data() {
      return {
        isFocused: false,
        isMounted: false,
      }
    },

    methods: {
      toggleListeners(action) {
        const input = this.getInput()

        input[`${action}EventListener`]('focus', this.onFocus)
      },
      toggleIsFocused() {
        this.isFocused = !this.isFocused
      },
      onBlur() {
        this.toggleIsFocused()
      },
      onFocus() {
        this.$emit('focus')
        this.toggleIsFocused()
      },
      onInput({ number }) {
        this.$validator.validate(this.name, number)
      },
      setAttributes() {
        const input = this.getInput()
        input.setAttribute('id', this.name)
        input.setAttribute('name', this.name)
      },
      attachValidation() {
        if (!this.validation) return

        this.$validator.attach({ name: this.name, rules: this.validation })
      },
      getInput() {
        return this.$refs.phone.$el.querySelector('input')
      },
      getPhoneInput() {
        const phone = this.$refs.phone.$el
        const arrow = phone.querySelector('.v-select__arrow')
        const dropdown = phone.querySelector('.prefix-dropdown')
        const telInput = phone.querySelector('.vue-tel-input input[type="tel"]')

        return {
          arrow,
          dropdown,
          telInput,
        }
      },
      addAria() {
        const { arrow, dropdown, telInput } = this.getPhoneInput()

        dropdown.setAttribute('aria-label', `Select area number for ${this.name}`)
        telInput.setAttribute('aria-describedby', this.descriptionId)
        telInput.setAttribute('aria-invalid', this.ariaInvalid)
        arrow.setAttribute('aria-hidden', true)
      },
      addTestid() {
        const { dropdown, telInput } = this.getPhoneInput()

        dropdown.setAttribute('data-testid', `${this.dataTestid}-dropdown`)
        telInput.setAttribute('data-testid', `${this.dataTestid}-input`)
      },
      onErrors(error) {
        const telInput = this.$refs.phone?.$el.querySelector('.vue-tel-input input[type="tel"]')

        return telInput?.setAttribute('aria-invalid', !!error.length)
      },
    },

    computed: {
      hasTooltip() {
        return this.tooltip.length
      },
      isDropdownOpen() {
        if (!this.isMounted) return

        return this.$refs.phone.open
      },
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
      ariaInvalid() {
        return !!this.errors.items.length
      },
      phone: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
  }
</script>

<style lang="scss">
  $dropdown: '.phone-input-group .vue-tel-input .dropdown > ul';

  .phone-input-group {
    position: relative;
    width: 100%;
    padding: 1.8rem 0 0 0;
    &-container {
      position: relative;
      font-size: 1.6rem;
    }
    &-label {
      display: inline-flex;
      padding: 0 0 0.8rem 0;
      color: color(dark-primary);
      line-height: 1;
      font-size: 1.4rem;
      font-weight: 600;
      cursor: pointer;
      &:active ~ .vue-tel-input {
        .dropdown, input {
          border-color: color(dark-primary);
        }
      }
    }
    .vue-tel-input {
      border: 0;
      &:focus {
        outline: none;
      }
      &:focus-within {
        box-shadow: none;
      }
      .vti {
        &__label {
          @include min-lg {
            font-size: 1.4rem;
          }
        }
        &__dropdown {
          margin-right: 0.8rem;
          padding: 1rem 1.2rem;
          position: relative;
          border: 1px solid color(input-border);
          transition: border-color .3s cubic-bezier(0, 0.8, 0.5, 1);
          &:hover,
          &.open {
            background-color: transparent;
          }
          &.open {
            z-index: z-index(over-all);
          }
          > ul {
            user-select: none;
            border-color: color(input-border);
            > li {
              color: color(dark-grey);
              font-size: 1.4rem;
              padding: 1.1rem 1.2rem;
            }
            .last-preferred {
              border-color: color(input-border);
            }
            strong {
              font-weight: 400;
            }
            @include max-sm {
              width: 100%;
              max-height: 100%;
              position: fixed;
              top: 0;
              left: 0;
            }
          }
        }
        &__dropdown-arrow {
          display: flex;
          align-items: center;
          margin-top: -.6rem;
          color: transparent;
          transform: none;
          &::before {
            content: '';
            display: block;
            width: .8rem;
            border: 1px solid transparent;
            border-right-color: color(dark-primary);
            border-bottom-color: color(dark-primary);
            height: .8rem;
            filter: blur(0);
            transform: rotate(45deg);
            transform-origin: bottom;
          }
        }
        &__input {
          width: 100%;
          height: 4rem;
          padding: 0.8rem 1.2rem;
          appearance: none;
          border: 1px solid color(input-border);
          border-radius: 0;
          color: color(dark-grey);
          font-size: 1.4rem;
          transition: border-color .3s cubic-bezier(0, 0.8, 0.5, 1);
        }
        &__dropdown:active,
        &__input:active {
          border-color: color(dark-primary);
        }
        &__flag {
          margin-left: 0;
        }
      }
      .base-select {
        padding: 0;
        margin-right: .8rem;
        .v-select__menu {
          margin-top: .2rem;
          min-width: 26rem;
          @include min-lg {
            min-width: 40rem;
          }
        }
      }
      &-label {
        @include min-lg {
          font-size: 1.4rem;
        }
      }
    }
    &--has-error {
      .phone-input-group-container:before {
        background-color: color(error);
      }
      .phone-input-group-label {
        color: color(error);
      }
      .vue-tel-input {
        .vti__dropdown {
          border-color: color(error);
        }
        input {
          border-color: color(error);
        }
      }
    }
    &--has-tooltip {
      .vue-tel-input input {
        padding-right: 3rem;
      }
    }
  }
  .main {
    &--default {
      #{$dropdown} {
        @include max-md {
          top: $header_height;
        }
      }
    }
    &--appbar {
      #{$dropdown} {
        @include max-md {
          top: $header_height;
          margin-top: -1px;
        }
      }
    }
  }
  .ios {
    .phone-input-group .vue-tel-input input {
      font-size: 1.6rem;
    }
  }
  .prefix-dropdown {
    .vti__flag {
      vertical-align: middle;
      margin-right: 0.8rem;
    }
    &__option {
      .vti__flag {
        display: inline-flex;
        margin-right: 1.6rem;
      }
    }
    &__country-name {
      margin-right: .8rem;
      vertical-align: middle;
    }
    &__prefix {
      vertical-align: middle;
    }
  }
</style>
