var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({attrs:{"label":_vm.$t(_vm.label)},on:{"errors":_vm.onErrors},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"phone-input-group",class:{
      'phone-input-group--has-error': errors.length,
      'phone-input-group--has-tooltip': _vm.hasTooltip,
      'phone-input-group--is-required': _vm.isRequired,
    },attrs:{"data-testid":_vm.dataTestid}},[_c('div',{staticClass:"phone-input-group-container"},[_c('label',{staticClass:"phone-input-group-label",class:{ 'phone-input-group-label--is-active': _vm.isFocused || _vm.value },attrs:{"for":_vm.name,"data-testid":(_vm.dataTestid + "-label")}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.labelText)}}),_c('screen-readers-only',[_vm._v(" "+_vm._s(_vm.ariaLabel)+" ")])],1),(_vm.hasTooltip)?_c('tooltip',{attrs:{"message":_vm.tooltip}}):_vm._e(),_c('vue-tel-input',{ref:"phone",attrs:{"aria-describedby":_vm.descriptionId,"mode":'international',"placeholder":'',"preferredCountries":['gb', 'pl']},on:{"onInput":_vm.onInput,"onBlur":_vm.toggleIsFocused},scopedSlots:_vm._u([{key:"dropdown",fn:function(ref){
          var sortedCountries = ref.sortedCountries;
          var activeCountry = ref.activeCountry;
          var choose = ref.choose;
return [_c('base-select',{staticClass:"prefix-dropdown",attrs:{"name":(_vm.name + "_prefix"),"options":sortedCountries,"value":activeCountry},on:{"input":choose},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
          var value = ref.value;
return [_c('div',{class:['vti__flag', value.iso2.toLowerCase()],attrs:{"aria-label":value.name}})]}},{key:"option",fn:function(ref){
          var option = ref.option;
return [_c('div',{staticClass:"prefix-dropdown__option"},[_c('div',{class:[
                    'vti__flag',
                    option.value.iso2.toLowerCase(),
                    'prefix-dropdown__flag'
                  ],attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"prefix-dropdown__country-name"},[_vm._v(_vm._s(option.value.name))]),_c('span',{staticClass:"prefix-dropdown__prefix"},[_vm._v("+"+_vm._s(option.value.dialCode))])])]}}],null,true)})]}}],null,true),model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)])]}}])},'form-group',{
    descriptionId: _vm.descriptionId,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }